"use client"
import styled from "@emotion/styled"
import { useTransitionUpStyles } from "./useTransitionUpStyles"
import { transitionEase, transitionTime, transitionUpValue } from "./utils"

export const TransitionUp = styled.div<{
  $inView: boolean
  $delay?: number
  $isInline?: boolean
}>`
  opacity: ${(p) => (p.$inView ? 1 : 0)};
  transition: all ${transitionTime}s ${transitionEase};
  transform: ${(p) =>
    p.$inView ? "translateY(0px)" : `translateY(${transitionUpValue})`};
  ${(p) => p.$delay && `transition-delay: ${p.$delay}s;`}
  ${(p) => p.$isInline && `display: inline;`}
`
export const TransitionUpComponent = ({
  children,
  inView,
  delay,
}: {
  children: ((transitionStyles: any) => React.ReactNode) | null | undefined
  inView: boolean
  delay?: number
}) => {
  const transitionStyles = useTransitionUpStyles(inView, delay)
  // ... existing code ...

  return children ? children(transitionStyles) : null
}
