import * as React from "react"

const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.6" y="0.6" width="46.8" height="46.8" rx="23.4" fill="white" />
    <rect
      x="0.6"
      y="0.6"
      width="46.8"
      height="46.8"
      rx="23.4"
      stroke="black"
      strokeWidth="1.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5998 14.6953C17.4223 14.6953 18.8998 16.1728 18.8998 17.9953C18.8998 19.8179 17.4223 21.2953 15.5998 21.2953C13.7773 21.2953 12.2998 19.8179 12.2998 17.9953C12.2998 16.1728 13.7773 14.6953 15.5998 14.6953ZM17.0998 17.9953C17.0998 17.1669 16.4282 16.4953 15.5998 16.4953C14.7714 16.4953 14.0998 17.1669 14.0998 17.9953C14.0998 18.8237 14.7714 19.4953 15.5998 19.4953C16.4282 19.4953 17.0998 18.8237 17.0998 17.9953Z"
      fill="black"
    />
    <path
      d="M22.7998 17.0953C22.3027 17.0953 21.8998 17.4983 21.8998 17.9953C21.8998 18.4924 22.3027 18.8953 22.7998 18.8953L34.7998 18.8953C35.2969 18.8953 35.6998 18.4924 35.6998 17.9953C35.6998 17.4983 35.2969 17.0953 34.7998 17.0953L22.7998 17.0953Z"
      fill="black"
    />
    <path
      d="M26.0998 29.9953C26.0998 29.4983 25.6969 29.0953 25.1998 29.0953L13.1998 29.0953C12.7027 29.0953 12.2998 29.4983 12.2998 29.9953C12.2998 30.4924 12.7027 30.8953 13.1998 30.8953L25.1998 30.8953C25.6969 30.8953 26.0998 30.4924 26.0998 29.9953Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3998 26.6953C30.5773 26.6953 29.0998 28.1728 29.0998 29.9953C29.0998 31.8179 30.5773 33.2953 32.3998 33.2953C34.2223 33.2953 35.6998 31.8179 35.6998 29.9953C35.6998 28.1728 34.2223 26.6953 32.3998 26.6953ZM30.8998 29.9953C30.8998 29.1669 31.5714 28.4953 32.3998 28.4953C33.2282 28.4953 33.8998 29.1669 33.8998 29.9953C33.8998 30.8237 33.2282 31.4953 32.3998 31.4953C31.5714 31.4953 30.8998 30.8237 30.8998 29.9953Z"
      fill="black"
    />
  </svg>
)
export default FilterIcon
