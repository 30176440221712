import { SVGProps } from "react"
const FooterArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25843 9.51739L12.0536 3.9909C12.333 3.72439 12.7862 3.72439 13.0656 3.9909L13.7416 4.63548C14.0206 4.90157 14.0211 5.33282 13.7427 5.59952L9.15 9.99999L13.7427 14.4005C14.0211 14.6672 14.0206 15.0984 13.7416 15.3645L13.0656 16.0091C12.7862 16.2756 12.3331 16.2756 12.0536 16.0091L6.25843 10.4826C5.97896 10.216 5.97896 9.78393 6.25843 9.51739Z"
      fill="white"
    />
  </svg>
)
export default FooterArrowIcon
