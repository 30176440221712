"use client"
import styled from "@emotion/styled"
import {Button, SxProps} from "@mui/material"
import Link from "next/link"
import { getNonUmbracoUrl } from "utils"
import { actionButtonClick } from "utils/gtm"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import {ComponentProps} from "react";

const StyledLinkWrapper = styled.div<{ cssString?: string }>`
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    div {
      max-width: 300px;
    }
  }
  a {
    font-size: 20px;
  }

  ${({ cssString }) => cssString}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  :hover {
    text-decoration: none;
  }
`

type Props = {
    link: { url: string; name: string }[]
    cssString?: string
    mainTitle?: string,
    variant?: ComponentProps<typeof Button>["variant"],
    customSx?: SxProps
}

export const LinkButton = ({ link, cssString, mainTitle, variant='outlined', customSx }: Props) => {
  return (
    <StyledLinkWrapper cssString={cssString}>
      <Desktop>
        <Button
          variant={variant}
          sx={{ marginInlineEnd: "auto", ...customSx }}
          LinkComponent={StyledLink}
          onClick={() => actionButtonClick(link[0]?.name, mainTitle || "")}
          href={getNonUmbracoUrl(link[0]?.url) || ""}
        >
            <span>{link[0]?.name}</span>
            {/*{link[0]?.name}*/}
        </Button>
      </Desktop>
      <Mobile>
        <Button
            variant={variant}
          sx={{ marginInlineEnd: "auto", ...customSx}}
          LinkComponent={StyledLink}
          href={getNonUmbracoUrl(link[0]?.url) || ""}
        >
          {link[0]?.name}
        </Button>
      </Mobile>
    </StyledLinkWrapper>
  )
}

export default LinkButton
