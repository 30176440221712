"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { usePathname } from "next/navigation"
import { FC, Suspense, useRef } from "react"
import { Desktop } from "../common/bit/MediaQueries"
import Analytics from "./Analytics"
import Footer from "./Footer/Footer"
import Header from "./Header"

const StyledMain = styled.div<{ isParking: boolean; isContent: boolean }>`
  padding-top: 64px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => (props.isContent ? "0px" : "98px")};
  }

  :focus {
    outline: 0;
  }
`

const StyledButton = styled(Button)`
  position: fixed;
  z-index: -10;
  background-color: black;
  color: white;
  top: -100px;
  &:focus-visible {
    z-index: 9999999;
    top: 0px;
  }
`

const Layout: FC<any> = ({
  locale,
  children,
  menu_data,
  footer_data,
  path,
  id,
  searchRoute,
  companyName,
  subcompany,
  projectType,
}) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const pathname = usePathname()

  const logo = footer_data?.header?.properties?.[0]?.value?.mediaItems?.[0]
  const handleClick = () => {
    if (mainRef.current) {
      mainRef.current.focus()
    }
  }

  return (
    <>
      <Suspense>
        <Analytics
          companyName={companyName}
          locale={locale}
          subcompany={subcompany}
          projectType={projectType}
        />
      </Suspense>

      <Desktop>
        <StyledButton id="skip-to-content" tabIndex={3} onClick={handleClick}>
          {locale === "he" ? "דלג לתוכן המרכזי" : "skip navigation"}
        </StyledButton>
      </Desktop>

      <Header
        logo={logo}
        data={menu_data}
        locale={locale}
        path={path}
        id={id}
        searchRoute={searchRoute}
        companyName={companyName}
      />
      {/* <Cookies /> */}

      <>
        <StyledMain
          isContent={pathname.includes("content")}
          isParking={pathname === "/parking" || pathname === "/en/parking"}
          id="mainContent"
          tabIndex={-1}
          ref={mainRef}
          role="main"
        >
          {children}
        </StyledMain>
        <Footer data={footer_data} companyName={companyName} />
      </>
    </>
  )
}

export default Layout
