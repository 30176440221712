"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import NewText from "./NewText/NewText"
import { FormFailIcon, FormSuccessIcon } from "./icons"
import { sharedColors as colors } from "./theme/colors"

const StyledForm = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: 400px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 400px;
  }
`
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledButton = styled(Button)`
  display: flex;
  width: 153px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px;
  border: 2px solid black;
  background: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  :hover {
    text-decoration: none;
  }
`

type Props = {
  state: "success" | "fail"
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
}
function SuccessFailJob({ state, setIsSubmitted }: Props) {
  return (
    <StyledForm>
      {state === "success" ? <FormSuccessIcon /> : <FormFailIcon />}
      <StyledText>
        <NewText component={"h3"} desktopFontSize={32} color={colors.black}>
          {state === "success" ? "פנייתך נשלחה בהצלחה!" : "פנייתך לא נשלחה"}
        </NewText>
        <NewText
          component={"p"}
          desktopFontSize={18}
          color={colors.black}
          maxWidth={"400px"}
        >
          {state === "success"
            ? "לורם איפסום דולר לורם איפסום דולר לורם איפסום דולר לורם איפסום"
            : ``}
        </NewText>
      </StyledText>
      {state === "fail" && (
        <StyledButton onClick={() => setIsSubmitted(false)}>
          ניסיון נוסף
        </StyledButton>
      )}
    </StyledForm>
  )
}

export default SuccessFailJob
