import { SVGProps } from "react"
const CloseCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7753 6.10894C13.0194 5.86486 13.0194 5.46913 12.7753 5.22505C12.5312 4.98097 12.1355 4.98097 11.8914 5.22505L8.99998 8.11645L6.10863 5.22511C5.86455 4.98104 5.46882 4.98104 5.22474 5.22512C4.98067 5.46919 4.98067 5.86492 5.22475 6.109L8.1161 9.00033L5.22475 11.8917C4.98067 12.1357 4.98067 12.5315 5.22474 12.7755C5.46882 13.0196 5.86455 13.0196 6.10863 12.7755L8.99998 9.8842L11.8914 12.7756C12.1355 13.0197 12.5312 13.0197 12.7753 12.7756C13.0194 12.5315 13.0194 12.1358 12.7753 11.8917L9.88387 9.00033L12.7753 6.10894Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00002 0.0419922C4.05247 0.0419922 0.041687 4.05277 0.041687 9.00033C0.041687 13.9479 4.05247 17.9587 9.00002 17.9587C13.9476 17.9587 17.9584 13.9479 17.9584 9.00033C17.9584 4.05277 13.9476 0.0419922 9.00002 0.0419922ZM1.29169 9.00033C1.29169 4.74313 4.74283 1.29199 9.00002 1.29199C13.2572 1.29199 16.7084 4.74313 16.7084 9.00033C16.7084 13.2575 13.2572 16.7087 9.00002 16.7087C4.74283 16.7087 1.29169 13.2575 1.29169 9.00033Z"
      fill="white"
    />
  </svg>
)
export default CloseCircleIcon
