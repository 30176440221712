"use client"

import styled from "@emotion/styled"
import { Button, ButtonBase } from "@mui/material"
import { useLocale } from "next-intl"
import Link from "next/link"
import { Key, useState } from "react"
import { Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { extractProperty, getNonUmbracoUrl, pageStripClick } from "utils"
import ProjectCard from "./../pages/ProjectsLobby/ProjectCard"
import Container from "./Container"
import NewText from "./NewText/NewText"
import { Desktop } from "./common/bit/MediaQueries"
import { LeftArrow, RightArrow } from "./icons"
import { sharedColors } from "./theme/colors"
type Props = {
  data: any
  projects: any
  filters: any
}

type ProjectProps = {
  absoluteUrl: string
  id: number
  key: string
  name: string
  url: string
  urlSegment: string
}

const StyledContainer = styled(Container)``

const StyledSwiper = styled(Swiper)`
  padding: 0;
  padding-bottom: 24px;
  padding-top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 24px;
  }
  .swiper-pagination {
    display: none;
  }
`

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 290px;
  margin-right: 16px;
  :last-child {
    margin-right: 0;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 24px;
    :last-child {
      margin-right: 0;
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
  svg {
    width: 20px;
  }
`

const BulletsFlex = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const Bullet = styled.button<{ active: boolean }>`
  width: 6px;
  height: 6px;
  cursor: pointer;
  padding: 0;
  background-color: ${({ active }) => (active ? "#2768A3" : "transparent")};
  border-radius: 200px;
  border: ${({ active }) => (active ? "none" : "1px solid #000000;")};
`

const StyledArrow = styled(ButtonBase)``

function ProjectGallerySwiper({ data, projects, filters }: Props) {
  const blockTitle = extractProperty(data, "blockTitle")
  const blockLink = extractProperty(data, "linkForMore")
  const linkTitle = blockLink?.[0]?.name
  const linkUrl = blockLink?.[0]?.url
  const [swiper, setSwiper] = useState(Object)
  const [activeIndex, setActiveIndex] = useState(0)
  const projectsArray: ProjectProps[] =
    data?.properties[2]?.propertyValue.contentList || []
  if (projectsArray.length === 0) {
    return null
  }

  const projectIds = projectsArray?.map((el: any) => el.id)
  const filteredProjects = projects
    ?.filter((project: any) => projectIds?.includes(project?.id))
    .sort(
      (a: any, b: any) => projectIds.indexOf(a.id) - projectIds.indexOf(b.id)
    )

  const locale = useLocale()

  type PropsBullet = {
    pagination: any
  }

  const PaginationBullets = ({ pagination }: PropsBullet) => {
    return (
      <BulletsFlex>
        {pagination && (
          <>
            <Desktop>
              <StyledArrow onClick={() => swiper.slidePrev()}>
                {locale === "he" ? <RightArrow /> : <LeftArrow />}
              </StyledArrow>
            </Desktop>
            {pagination?.map((_: any, index: Key | null | undefined) => (
              <Bullet
                active={index === activeIndex}
                key={index}
                onClick={() => swiper?.slideTo(index)}
              />
            ))}
            <Desktop>
              <StyledArrow onClick={() => swiper.slideNext()}>
                {locale === "he" ? <LeftArrow /> : <RightArrow />}
              </StyledArrow>
            </Desktop>
          </>
        )}
      </BulletsFlex>
    )
  }

  return (
    <StyledContainer>
      <NewText
        component={"h2"}
        desktopFontSize={32}
        desktopFontWeight={600}
        desktopLineHeight={"36px"}
        mobileFontSize={32}
        mobileLineHeight={"36px"}
        color={sharedColors.black}
      >
        {blockTitle}
      </NewText>
      <StyledSwiper
        slidesPerView={"auto"}
        modules={[Pagination, Navigation]}
        onSwiper={setSwiper}
        pagination
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {filteredProjects.map((el: any, index: any) => {
          return (
            <StyledSwiperSlide key={index}>
              <ProjectCard
                project={el}
                countries={filters.countries.options}
                statuses={filters.statuses.options}
                subsidiaries={filters.subsidiaries.options}
                key={el.projectTitle}
                toProject={""}
                locale={locale}
                cities={filters.cities.options}
                designation={filters.designations.options}
              />
            </StyledSwiperSlide>
          )
        })}
      </StyledSwiper>
      <Container withoutPadding>
        <ButtonsContainer>
          <PaginationBullets pagination={swiper?.pagination?.bullets} />
          <Link href={linkUrl ? getNonUmbracoUrl(linkUrl) : "/projects"}>
            <Button
              variant="outlined"
              size="large"
              onClick={() => pageStripClick(linkTitle, blockTitle)}
            >
              {linkTitle || "לפרויקטים נוספים"}
            </Button>
          </Link>
        </ButtonsContainer>
      </Container>
    </StyledContainer>
  )
}

export default ProjectGallerySwiper
