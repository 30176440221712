"use client"

import styled from "@emotion/styled"
import { Button, Tab, Tabs } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import { EffectCoverflow, Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
import { getUmbracoMediaUrl } from "utils"
import Container from "./Container"
import NewText from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import { NextArrow, ReadMoreIcon } from "./icons"
import { sharedColors as colors } from "./theme/colors"

const StyledBackground = styled.div<{ $background: string }>`
  background: ${(props) => props.$background};
  padding: 0px;
  padding-top: 36px;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px;
  }
`

const StyledItemContainer = styled.div<{
  transformLeft?: boolean
  transformRight?: boolean
}>`
  height: 380px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  transition: 200ms all ease;
  cursor: pointer;
  margin: 40px 0;

  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 280px;
    width: 212.1px;
    box-shadow: none;
    padding: 0px;
    transform-origin: ${(props) =>
      props.transformLeft
        ? "left center"
        : props.transformRight
        ? "right center"
        : "center"};
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 323.58490566px;
    width: 245.83333333px;
  }
  :hover {
    transform: scale(1.065);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  }
`

const StyledIcon = styled.div`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  position: relative;
`

const StyledTabs = styled(Tabs)`
  margin-left: 20px;
  border-top: 1px solid #e5edfa;
  .MuiTab-root {
    border-top: 4px solid transparent;
    padding-top: 0;
    color: ${(props) => props.theme.colors.white};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
  }
  .Mui-selected {
    border-top: 4px solid white;
    color: ${(props) => props.theme.colors.white} !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 0px;
  }
`

const StyledCTA = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  margin-left: 32px;
  margin-bottom: 34px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 25.2px;
    margin-left: 32px;
    margin-bottom: 28px;
  }
`

const StyledTitleContainer = styled.div`
  margin-left: 20px;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
    margin-left: 0px;
  }
`

const StyledRelative = styled.div`
  position: relative;
`

const StyledNext = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% / 7);
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: calc(100% / 20);
  }
`

const StyledPrev = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  left: calc(100% / 7);
  ${(props) => props.theme.breakpoints.up("lg")} {
    left: calc(100% / 20);
  }
`
interface ItemType {
  icon: string
  text: string
  url: string
}

interface TabType {
  tabLabel: string
  items: ItemType[]
}

function convertToTabsData(data: any) {
  // Validate the input structure
  if (
    data.__typename !== "BasicBlockListItem" ||
    data.blockType !== "linksLists"
  ) {
    throw new Error("Invalid input data structure.")
  }

  // Extract lists
  const lists = data.properties.find(
    (prop: any) => prop.propertyName === "lists"
  ).propertyValue.blocks

  // Convert lists into tabsData format
  const tabsData: TabType[] = lists.map((list: any) => {
    const tabLabel: string = list.properties.find(
      (prop: any) => prop.propertyName === "title"
    ).propertyValue.value
    const items = list.properties.find(
      (prop: any) => prop.propertyName === "urls"
    ).propertyValue.blocks

    const convertedItems: ItemType[] = items.map((item: any) => {
      const url = item.properties.find(
        (prop: any) => prop.propertyName === "link"
      ).propertyValue.links[0].url
      const text = item.properties.find(
        (prop: any) => prop.propertyName === "linkTitle"
      ).propertyValue.value
      const iconUrl = item.properties.find(
        (prop: any) => prop.propertyName === "linkImage"
      ).propertyValue.mediaItems[0].url

      return {
        icon: iconUrl,
        text: text,
        url,
      }
    })

    return {
      tabLabel: tabLabel,
      items: convertedItems,
    }
  })

  return tabsData
}

const SLIDES_DESKTOP = 6

// TO DO : HOW TO FIND DATA TYPE IN GENERATED GRAPHQL TYPES

function Brands({
  data,
  background = colors.blue,
}: {
  data: any
  background?: string
}) {
  const tabsData = convertToTabsData(data)
  const [value, setValue] = useState(0)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [active, setActive] = useState<number | null>(null)
  return (
    <StyledBackground $background={background}>
      {tabsData.length > 1 ? (
        <>
          <Desktop>
            <Container>
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {tabsData.map((tab: TabType, index: number) => (
                  <Tab label={tab.tabLabel} key={index} />
                ))}
              </StyledTabs>
            </Container>
          </Desktop>
          <Mobile>
            <StyledTitleContainer>
              <NewText
                desktopFontSize={32}
                component="h1"
                desktopLineHeight="150%"
                desktopFontWeight="500"
                color={colors.white}
              >
                מותגי הקבוצה
              </NewText>
            </StyledTitleContainer>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabsData.map((tab: TabType, index: number) => (
                <Tab
                  label={
                    <NewText
                      desktopFontSize={18}
                      component="h1"
                      desktopLineHeight="140%"
                      desktopFontWeight="500"
                      color={colors.white}
                    >
                      {tab.tabLabel}
                    </NewText>
                  }
                  key={index}
                />
              ))}
            </StyledTabs>
          </Mobile>
        </>
      ) : tabsData.length === 1 ? (
        <Container>
          <NewText
            desktopFontSize={48}
            component="h1"
            desktopLineHeight="48px"
            desktopFontWeight="500"
            color={colors.black}
          >
            {tabsData?.[0]?.tabLabel}
          </NewText>
        </Container>
      ) : null}
      <Desktop>
        <StyledRelative>
          <StyledNext className="arrow-left arrow">
            <NextArrow />
          </StyledNext>
          <StyledPrev className="arrow-right arrow">
            <NextArrow />
          </StyledPrev>
          <Container>
            <Swiper
              navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
              key={value}
              style={{ padding: 0 }}
              modules={[Navigation]}
              slidesPerView={SLIDES_DESKTOP}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              {tabsData[value].items.map((item: ItemType, index: number) => {
                return (
                  <SwiperSlide
                    key={index.toString()}
                    onMouseOver={() => setActive(index)}
                    onMouseLeave={() => setActive(null)}
                    style={{ zIndex: active === index ? 9 : 1 }}
                  >
                    <StyledItemContainer
                      transformLeft={activeIndex === index}
                      transformRight={
                        index === activeIndex + SLIDES_DESKTOP - 1
                      }
                    >
                      <StyledIcon>
                        <Image
                          style={{ objectFit: "contain" }}
                          src={getUmbracoMediaUrl(item.icon)}
                          fill
                          alt={item.text}
                        />
                      </StyledIcon>
                      <StyledCTA href={item.url}>
                        {item.text}
                        <ReadMoreIcon />
                      </StyledCTA>
                    </StyledItemContainer>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Container>
        </StyledRelative>
      </Desktop>
      <Mobile>
        <Swiper
          style={{ padding: 0 }}
          key={2}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1.065,
            slideShadows: false,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {tabsData[value].items.map((item: ItemType, index: number) => {
            return (
              <SwiperSlide
                key={index.toString()}
                onMouseOver={() => {
                  setActive(index)
                }}
                onMouseLeave={() => {
                  setActive(null)
                }}
                style={{ zIndex: active === index ? 9 : 1 }}
              >
                <StyledItemContainer>
                  <StyledIcon>
                    <Image
                      style={{ objectFit: "contain" }}
                      src={getUmbracoMediaUrl(item.icon)}
                      fill
                      alt={item.text}
                    />
                  </StyledIcon>
                  <StyledCTA href={item.url}>
                    {item.text}
                    <ReadMoreIcon />
                  </StyledCTA>
                </StyledItemContainer>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Mobile>
    </StyledBackground>
  )
}

export default Brands
