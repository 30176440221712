"use client"

import styled from "@emotion/styled"
import { IconButton, Input } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useLocale } from "next-intl"
import { useRef, useState } from "react"
import { extractProperty, getUmbracoMediaUrl } from "utils"

import Container from "./Container"
import NewText from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import { XIcon } from "./icons"
import SearchIconNew from "./icons/SearchIconNew"
import VideoMuteIcon from "./icons/video/VideoMuteIcon"
import VideoPauseIcon from "./icons/video/VideoPauseIcon"
import VideoPlayIcon from "./icons/video/VideoPlayIcon"
import VideoUnmuteIcon from "./icons/video/VideoUnmuteIcon"
import Results from "./layout/Results"
import { sharedColors } from "./theme/colors"

const StyledDiv = styled.div`
  padding: 32px 0px 32px 0px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px 0px 60px 0px;
    text-align: center;
  }
`

const StyledContainer = styled(Container)`
  text-align: center;
  display: flex;
  justify-content: right;
  flex-direction: column;
  position: relative;
  padding: 0px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    h1 {
      text-align: left;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
    justify-content: center;
  }
`
const StyledVideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000917;
  height: 340px;
  width: 100%;
  border-radius: 16px;
  margin-top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 382px;
    margin-top: 24px;
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 16px;
  }
`

const StyledContainerBottom = styled(Container)`
  position: absolute;
  inset: 0;
`

const StyledButtonsWrapper = styled.div`
  position: relative;
  z-index: 1;
  direction: rtl;
  display: flex;
  position: absolute;
  inset-inline-start: 0;
  bottom: 16px;
  right: 32px;
  button {
    padding: 0;
    &:focus-visible {
      border: 2px dashed white;
      outline-offset: 2px;
      outline: 3px dashed #10059f;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    bottom: 0px;
    inset-inline-start: 8px;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
`

const StyledContainerSearch = styled.div``

const StyledModalBox = styled.div<{ hasBottomRadius: boolean }>`
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 32px);
  height: 72px;
  background-color: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 16px;
  margin: 0 auto;
  input {
    padding: 0px;
    height: 100%;
    &::placeholder {
      color: ${sharedColors.black};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    top: 50%;
    width: 560px;
  }
`
type Result = {
  items: []
}
const fetchSearchResults = async (text: string, path: string) => {
  // Replace this with the actual API call
  const GRAPHQL_URL = process.env.GRAPHQL_URL?.replace("/graphql", "")
  const PROJECTS_PATH = process.env.ENV?.includes("production")
    ? "-1,4802,4823,4861"
    : "-1,1540,1543,1556"

  const fetchPages = fetch(
    `${GRAPHQL_URL}/generic/search?path=${path}&term=${text}`
  )
  const fetchProjects = fetch(
    `${GRAPHQL_URL}/generic/search?path=${PROJECTS_PATH}&term=${text}&pageSize=1000`
  )
  const [pagesJSON, projectsJSON] = await Promise.all([
    fetchPages,
    fetchProjects,
  ])
  const pages: Result = await pagesJSON.json()
  const projects: Result = await projectsJSON.json()

  return { pages, projects }
}

type Props = {
  data: any
  id: string[]
  searchRoute?: string
  path?: string
}
function HeaderWithSearch({ data, id, searchRoute, path }: Props) {
  const pageMainTitle = extractProperty(data, "pageMainTitle")
  const heroBannerDescktop = extractProperty(data, "heroBannerDescktop")
  const heroBannerMobile = extractProperty(data, "heroBannerMobile")

  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const mobileVideoRef = useRef<HTMLVideoElement>(null)
  const [text, setText] = useState<string>("")
  const [debouncedText, setDebouncedText] = useState<string>(text)

  const close = () => {
    setText("")
    setDebouncedText("")
  }
  const clear = () => {
    setText("")
    setDebouncedText("")
  }
  const locale = useLocale()

  const { data: searchResults, isFetching } = useQuery({
    queryKey: ["search", debouncedText],
    queryFn: () => fetchSearchResults(debouncedText, path || ""),
    enabled: !!debouncedText,
    refetchOnWindowFocus: false,
  })
  const pageIDs =
    (searchResults?.pages?.items?.map((el: any) => el.id) as number[]) ?? []
  const projectIDs =
    (searchResults?.projects?.items?.map((el: any) => el.id) as number[]) ?? []

  const handlePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
    if (mobileVideoRef.current) {
      if (isPlaying) {
        mobileVideoRef.current.pause()
        setIsPlaying(false)
      } else {
        mobileVideoRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  const handleMute = () => {
    if (videoRef.current) {
      if (isMuted) {
        videoRef.current.muted = false
        setIsMuted(false)
      } else {
        videoRef.current.muted = true
        setIsMuted(true)
      }
    }
    if (mobileVideoRef.current) {
      if (isMuted) {
        mobileVideoRef.current.muted = false
        setIsMuted(false)
      } else {
        mobileVideoRef.current.muted = true
        setIsMuted(true)
      }
    }
  }

  return (
    <StyledDiv>
      <StyledContainer>
        <NewText
          component={"h1"}
          desktopFontSize={48}
          desktopFontWeight={600}
          desktopLineHeight={"70px"}
          mobileFontSize={32}
          mobileLineHeight={"46px"}
          mobileFontWeight={400}
          color={sharedColors.black}
          maxWidth={"800px"}
        >
          {pageMainTitle}
        </NewText>
        <StyledVideoContainer>
          <Desktop>
            <StyledVideo
              autoPlay
              loop
              muted={isMuted}
              ref={videoRef}
              playsInline
            >
              <source
                src={getUmbracoMediaUrl(heroBannerDescktop)}
                type="video/mp4"
              />
            </StyledVideo>
          </Desktop>
          <Mobile>
            <StyledVideo
              autoPlay
              loop
              muted={isMuted}
              ref={mobileVideoRef}
              playsInline
            >
              <source
                src={getUmbracoMediaUrl(heroBannerMobile)}
                type="video/mp4"
              />
            </StyledVideo>
          </Mobile>
          <StyledContainerBottom>
            <StyledButtonsWrapper>
              <StyledButton
                aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
                onClick={() => handlePlay()}
              >
                {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
              </StyledButton>
              <StyledButton
                aria-label={isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"}
                onClick={() => handleMute()}
              >
                {isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}
              </StyledButton>
            </StyledButtonsWrapper>
          </StyledContainerBottom>
        </StyledVideoContainer>
        <StyledContainerSearch>
          <StyledModalBox hasBottomRadius={!!debouncedText}>
            <Input
              onChange={(e) => setText(e.target.value)}
              value={text}
              sx={{
                "&.MuiInput-root:before": {
                  borderBottom: "none",
                },
                "&.MuiInput-root:after": {
                  borderBottom: "none",
                },
                "&.MuiInput-root:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
              fullWidth
              placeholder={locale === "he" ? "חיפוש באתר..." : "Search"}
              style={{ height: "40px", padding: 0 }}
              startAdornment={
                debouncedText && (
                  <IconButton onClick={clear}>
                    <XIcon />
                  </IconButton>
                )
              }
              endAdornment={
                <IconButton
                  style={{
                    background: "transparent",
                    color: "white",
                  }}
                  onClick={() => setDebouncedText(text)}
                >
                  <SearchIconNew />
                </IconButton>
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setDebouncedText(text)
                }
              }}
            />
          </StyledModalBox>
          {debouncedText?.length > 0 ? (
            <Results
              pageIDs={pageIDs}
              projectIDs={projectIDs}
              handleClose={close}
              culture={"he-il"}
              isLoadingQuery={isFetching}
              word={debouncedText}
              idNumber={id}
              searchRouteString={searchRoute || ""}
              width={"560px"}
              top={"63%"}
              height={"195px"}
            />
          ) : null}
        </StyledContainerSearch>
      </StyledContainer>
    </StyledDiv>
  )
}

export default HeaderWithSearch
