import { GetPageQuery } from "apollo"
import { extractProperty } from "./extractProperty"
import { getNonUmbracoUrl } from "./getNonUmbracoUrl"

type Parent = {
  url: string | null | undefined
  id: number | null | undefined
  level: number | null | undefined
  parent: Parent
}

export type Breadcrumbs = {
  url: string
  title: string
}[]

function extractTitle(data: any) {
  const pageBlock = extractProperty(data, "pageInfoAndMetaTags")
  if (pageBlock) {
    data = pageBlock?.[0]
  }
  const title = extractProperty(data, "titleBreadcrumbs") as string | undefined
  return title
}

export function getBreadCrumbs(data: GetPageQuery, locale: string) {
  const self = extractTitle(data.page)
  const selfObject: { url: string; title: string } = {
    title: self || "",
    url: getNonUmbracoUrl(data.page?.url || ""),
  }
  const parent = data.page?.parent as Parent
  const parents: Array<{ url: string; title: string }> = []
  function recurse(currentParent: Parent | null) {
    if (
      currentParent &&
      currentParent.level !== null &&
      currentParent.level !== undefined &&
      currentParent.level >= 3
    ) {
      const parent = extractTitle(currentParent)
      if (parent) {
        parents.push({
          url: getNonUmbracoUrl(currentParent.url || ""),
          title: parent || "",
        })
      }

      if (currentParent.parent) {
        recurse(currentParent.parent)
      }
    }
  }
  recurse(parent ?? null)
  return {
    selfObject,
    parents: [
      {
        title: locale === "he" ? "ראשי" : "Homepage",
        url: locale === "he" ? "/" : "/en",
      },
      ...parents.reverse(),
    ],
  }
}
